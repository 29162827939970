<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Panel header="Gerenciamento de Editais">
                <Toolbar class="p-mb-3">
                    <template v-slot:left>
                        <Button label="Novo" icon="pi pi-plus" class="p-button-success" @click="showCreateEdital()" />
                    </template>
                    <template v-slot:right> </template>
                </Toolbar>
                <DataTable ref="dt" :value="editais" class="p-datatable-sm" v-model:selection="selectedLink" dataKey="id"
                    :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]" :filters="filters"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} usuários">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5>Editais</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Pesquisar..." />
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        <div class="p-text-center">Nenhum resultado encontrado...</div>
                    </template>
                    <Column header="N° do Edital" field="nedital" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">N° do Edital:</span>
                            {{ slotProps.data.nedital.toUpperCase() }}
                        </template>
                    </Column>
                    <Column header="Hospital" field="nhospital" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Hospital:</span>
                            {{ slotProps.data.nhospital.toUpperCase() }}
                        </template>
                    </Column>
                    <Column header="Município" field="municipio">
                        <template #body="slotProps">
                            <span class="p-column-title">Município:</span>
                            {{ slotProps.data.municipio.toUpperCase() }}
                        </template>
                    </Column>
                    <Column header="Visibilidade" field="visibilidade">
                        <template #body="slotProps">
                            <span class="p-column-title">Visibilidade:</span>
                            <span v-if="slotProps.data.visibilidade === true"
                                :class="'product-badge visibilidade-' + slotProps.data.visibilidade"
                                @click="updateVisibilidadeEdital(slotProps.data)" style="cursor: pointer">Visível</span>
                            <span v-if="slotProps.data.visibilidade === false"
                                :class="'product-badge visibilidade-' + slotProps.data.visibilidade"
                                @click="updateVisibilidadeEdital(slotProps.data)" style="cursor: pointer">Não Visível</span>
                        </template>
                    </Column>
                    <Column header="Status" field="status">
                        <template #body="slotProps">
                            <span class="p-column-title">Status:</span>
                            <span v-if="slotProps.data.status === true"
                                :class="'product-badge status-' + slotProps.data.status"
                                @click="updateStatusEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA INATIVAR'"
                                style="cursor: pointer">ABERTO</span>
                            <span v-if="slotProps.data.status === false"
                                :class="'product-badge status-' + slotProps.data.status"
                                @click="updateStatusEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA ATIVAR'"
                                style="cursor: pointer">ENCERRADO</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <span class="p-column-title">Ações:</span>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                @click="showUpdateEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA EDITAR'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                @click="showDeleteEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA EXCLUIR'" />
                        </template>
                    </Column>
                </DataTable>
            </Panel>
        </div>
    </div>
    <!--MODAL CADASTRAR-->
    <Dialog v-model:visible="editalDialog" :header="titleDialog" :style="{ width: '800px' }" :modal="true"
        @hide="hideDialogs()">
        <form @submit.prevent="createOrUpdateEdital(v$.edital.$invalid)">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 ">
                    <label for="nEdital">Nº do Edital</label>
                    <InputText 
                        id="nEdital" 
                        v-model="v$.edital.nedital.$model" 
                        placeholder="Digite o N° do Edital."
                        :class="{ 'p-invalid': submitted && v$.edital.nedital.$invalid }" 
                        autofocus 
                    />
                    <small class="p-invalid" v-if="submitted && v$.edital.nedital.$invalid">
                        N° do Edital é obrigatório.
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label for="nHospital">Hospital</label>
                    <InputText 
                        id="nHospital" 
                        v-model="v$.edital.nhospital.$model" 
                        placeholder="Digite o nome do hospital."
                        :class="{ 'p-invalid': submitted && v$.edital.nhospital.$invalid }" 
                    />
                    <small class="p-invalid" v-if="submitted && v$.edital.nhospital.$invalid">
                        Hospital é obrigatório.
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label for="municipio">Município</label>
                    <InputText 
                        id="municipio" 
                        v-model="v$.edital.municipio.$model" 
                        placeholder="Digite o município."
                        :class="{ 'p-invalid': submitted && v$.edital.municipio.$invalid }" 
                    />
                    <small class="p-invalid" v-if="submitted && v$.edital.municipio.$invalid ">
                        Município é obrigatório.
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label for="nPae">N° Pae</label>
                    <InputText 
                        id="nPae" 
                        v-model="v$.edital.npae.$model" 
                        placeholder="Digite o numero do processo."
                        :class="{ 'p-invalid': submitted && v$.edital.npae.$invalid  }" />
                    <small class="p-invalid" v-if="submitted && v$.edital.npae.$invalid">
                        N° do Processo é obrigatório.
                    </small>
                </div>
                <div class="p-field p-col-12 ">
                    <label for="nome">Visibilidade</label>
                    <Dropdown 
                        v-model="edital.visibilidade" 
                        :options="visibilidades" 
                        optionLabel="name" 
                        optionValue="key"
                        placeholder="Selecione a Visibilidade"
                    />
                </div>
                <div class="p-field p-col-12">
                    <label for="link">Status</label>
                    <Dropdown 
                        v-model="edital.status" 
                        :options="status" 
                        optionLabel="name" 
                        optionValue="key"
                        placeholder="Selecione o Status" 
                    />
                </div>
            </div>
            <Button label="Salvar" class="p-button-raised p-mr-2" icon="pi pi-check" type="submit" />
            <Button label="Cancelar" icon="pi pi-times" class="p-button-raised p-button-text" @click="hideDialogs()" />
        </form>
    </Dialog>
    <!--FIM MODAL CADASTRAR-->
    <!--MODAL DELETAR-->
    <Dialog v-model:visible="deleteEditalDialog" :header="titleDialog" :style="{ width: '450px' }" :modal="true"
        @hide="hideDialogs()">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="edital">Tem certeza de que deseja excluir o edital
                <b>{{ edital.nedital }}  | {{edital.nhospital}}?</b>
            </span>
        </div>
        <template #footer>
            <Button label="Sim" icon="pi pi-check" class="p-button-raised" @click="deleteEdital()" />
            <Button label="Não" icon="pi pi-times" class="p-button-raised p-button-text" @click="hideDialogs()" />
        </template>
    </Dialog>
    <!--FIM MODAL DELETAR-->
</template>
  
<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { FilterMatchMode } from "primevue/api";

import EditalService from "@/service/Edital/EditalService";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            editalDialog: false,
            deleteEditalDialog: false,
            filters: {},
            edital: {
                id: null,
                municipio: null,
                visibilidade: false,
                status: true,
                nhospital: null,
                nedital: null,
                npae: null
            },
            editais: null,
            selectedEdital: null,
            submitted: false,
            status: [{ key: true, name: "ABERTO" }, { key: false, name: "ENCERRADO" }],
            visibilidades: [{ key: true, name: "VISÍVEL" }, { key: false, name: "NÃO VISÍVEL" }]
        };
    },
    editalService: null,
    validations() {
        return {
            edital: {
                municipio: {
                    required,
                },
                nhospital: {
                    required,
                },
                nedital: {
                    required,
                },
                npae: {
                    required,
                },
            },
        };
    },
    created() {
        this.editalService = new EditalService();
        this.initFilters();
    },
    mounted() {
        this.findAll();
    },
    methods: {
        showCreateEdital() {
            this.titleDialog = "Novo  Edital";
            this.editalDialog = true;
        },
        showUpdateEdital(edital) {
            this.edital = edital;
            this.titleDialog = `${edital.nedital} | ${edital.nhospital} `;
            this.editalDialog = true;
        },
        showDeleteEdital(edital) {
            this.edital = edital;
            this.titleDialog = "Exclusão de Edital";
            this.deleteEditalDialog = true;
        },
        hideDialogs() {
            this.edital = {
                id: null,
                municipio: null,
                visibilidade: true,
                status: true,
                nhospital: null,
                nedital: null,
                npae: null
            };
            this.submitted = false;
            this.selectedEdital = null;
            this.editalDialog = false;
            this.deleteEditalDialog = false;
        },
        findAll() {
            this.editalService
                .findAll()
                .then((data) => {
                    this.editais = data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        createOrUpdateEdital(isFormValid) {
            this.submitted = true;
            if (isFormValid) {
                return;
            }
            if (this.edital.id === null) {
                this.editalService
                    .create(this.edital)
                    .then((data) => {
                        this.$msgSuccess(data);
                        this.findAll();
                        this.hideDialogs();
                    }).catch((error) => {
                        this.$msgErro(error);
                    });
            } else {
                this.editalService
                    .update(this.edital.id, this.edital)
                    .then((data) => {
                        if (data.status === 200) {
                            this.$msgSuccess(data);
                            this.findAll();
                            this.hideDialogs();
                        }
                    }).catch((error) => {
                        this.$msgErro(error);
                    });
            }
        },
        deleteEdital() {
            this.editalService
                .delete(this.edital.id)
                .then((data) => {
                    if (data.status === 204) {
                        this.$msgSuccess(data);
                        this.findAll();
                        this.hideDialogs();
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    } else {
                        this.$msgErro(error);
                    }
                });
        },
        updateStatusEdital(edital) {
            this.edital = edital;
            if (this.edital.status === true) {
                this.editalService
                .updateStatus(this.edital.id, "false")
                .then(() => {
                    this.findAll()
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                    this.$msgWarn(null);
                    } else {
                    this.$msgErro(error);
                    }
                });
            } else {
                this.editalService
                .updateStatus(this.edital.id, "true")
                .then(() => {
                    this.findAll();
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                    this.$msgWarn(null);
                    } else {
                    this.$msgErro(error);
                    }
                });
            }
        },
        updateVisibilidadeEdital(edital) {
            this.edital = edital;
            if (this.edital.visibilidade === true) {
                this.editalService
                .updateVisibilidade(this.edital.id, "false")
                .then(() => {
                    this.findAll();
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                    this.$msgWarn(null);
                    } else {
                    this.$msgErro(error);
                    }
                });
            } else {
                this.editalService
                .updateVisibilidade(this.edital.id, "true")
                .then(() => {
                    this.findAll();
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                    this.$msgWarn(null);
                    } else {
                    this.$msgErro(error);
                    }
                });
            }
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style lang="scss" scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-invalid {
    color: red;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.visibilidade-true {
        background: #c8e6c9;
        color: #256029;
    }

    &.visibilidade-false {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-true {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-false {
        background: #ffcdd2;
        color: #c63737;
    }
}

.p-datatable-sm .p-datatable-tbody>tr>td .p-column-title {
    display: none !important;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-sm {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr>td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -0.4em 1em -0.4em -0.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                    text-align: right;
                }

                .p-rating {
                    display: inline-block;
                }
            }
        }
    }
}
</style>
  